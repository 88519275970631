import type { Locale } from '@wanda-space/ops-types'

import type { AccountId, WandaId } from '@wanda-space/types'
import type { ServiceOrderState, SupportedCities, SupportedCountries } from '../../consts/enums'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { ApiError } from '../error'
import type { OrderLine, OrderLineInput } from '../types'

export interface ServiceOrder {
  id: string
  createdAt: Date
  updatedAt: Date
  state: ServiceOrderState
  accountId: AccountId
  simpleId: string
  contactInfo: {
    firstName?: string | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    email: string
    locale: Locale
  }
  city: SupportedCities
  countryCode: SupportedCountries
  orderLines: OrderLine[]
  handledHandovers: string[]
  extraInfo: string
  internalComment: string
  serviceProviderId: string
}

export async function fetchServiceOrders(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: ServiceOrder[]; count: number }> {
  return fetchChunk({ url: '/admin/service-orders', ...params, search: params.search?.trim() })
}

export async function fetchServiceOrder(id: string, token: string): Promise<ServiceOrder> {
  const response = await fetchServiceOrders({
    token,
    filters: { id },
    itemsPerPage: 1,
    page: 0,
    orderDir: 'ASC',
    orderBy: 'id',
    search: undefined,
  })
  if (response.count === 1) {
    return response.items[0]
  }
  throw new ApiError('/admin/service-orders', { method: 'GET' }, { status: 404 } as Response)
}

export const fulfillServiceOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<ServiceOrder> => {
  return request({
    method: 'POST',
    url: `/admin/service-orders/${orderId}/fulfill`,
    token,
    body: {},
  })
}

export const completeServiceOrderItem = async ({
  orderId,
  itemId,
  token,
}: {
  orderId: string
  itemId: string
  token: string
}): Promise<ServiceOrder> => {
  return request({
    method: 'POST',
    url: `/admin/service-orders/${orderId}/complete-item/${itemId}`,
    token,
    body: {},
  })
}

export const rejectServiceOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<ServiceOrder> => {
  return request({
    method: 'POST',
    url: `/admin/service-orders/${orderId}/reject`,
    token,
    body: {},
  })
}

export const setServiceOrderInProgress = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<ServiceOrder> => {
  return request({
    method: 'POST',
    url: `/admin/service-orders/${orderId}/set-in-progress`,
    token,
    body: {},
  })
}

export interface CreateServiceOrder {
  accountId: AccountId
  city: SupportedCities
  countryCode: SupportedCountries
  orderLines: OrderLineInput[]
  extraInfo: string
  internalComment: string
}

export async function createServiceOrder(
  serviceOrder: CreateServiceOrder,
  token: string,
): Promise<ServiceOrder> {
  return request({
    method: 'POST',
    url: '/admin/service-orders',
    token,
    body: serviceOrder,
  })
}

export async function updateServiceOrderComments(
  id: string,
  comments: { extraInfo: string; internalComment: string },
  token: string,
) {
  return request({
    method: 'PUT',
    url: `/admin/service-orders/${id}/comments`,
    token,
    body: comments,
  })
}
export async function getServiceOrdersByItemId({
  id,
  token,
}: {
  id: string
  token: string
}): Promise<ServiceOrder[]> {
  return request({
    method: 'GET',
    url: `/admin/service-orders/by-item/${id}`,
    token,
  })
}
