import { keys } from 'ramda'

export enum StorageItemType {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  BOX = 'BOX',
}

export enum StorageItemState {
  ON_THE_ROAD = 'ON_THE_ROAD',
  STORED = 'STORED',
  WITH_CUSTOMER = 'WITH_CUSTOMER',
  WITH_PARTNER = 'WITH_PARTNER',
}

export enum DeliveryTimeSlots {
  _1000__1600 = '10:00 - 16:00',
  _1600__2200 = '16:00 - 22:00',
}

export enum OrderState {
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
  PAYMENT_AUTHORISED = 'PAYMENT_AUTHORISED',
  ORDER_REJECTED = 'ORDER_REJECTED',
  DEVIATED = 'DEVIATED',
  SHIPMENT_HANDOVER_COMPLETED = 'SHIPMENT_HANDOVER_COMPLETED',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
}

export enum OrderType {
  PICK_UP = 'PICK_UP',
  DELIVERY = 'DELIVERY',
}

export enum OrderDetailsTabs {
  ACTIONS = 'actions',
  ITEMS = 'items',
  DETAILS = 'details',
}

export enum SupportedCountries {
  NO = 'NO',
  SE = 'SE',
}

export enum SupportedCities {
  NO_OSLO = 'NO_OSLO',
  NO_STAVANGER = 'NO_STAVANGER',
  NO_TRONDHEIM = 'NO_TRONDHEIM',
  SE_STOCKHOLM = 'SE_STOCKHOLM',
  SE_GOTHENBURG = 'SE_GOTHENBURG',
}

export enum CategoryType {
  CAT1 = 1,
  CAT2 = 2,
  CAT3 = 3,
  CAT4 = 4,
  CAT5 = 5,
}

export const CitiesTabs = keys(SupportedCities).map((city) => ({
  name: city.split('_')[1],
  value: city,
}))

export interface CategoryRange {
  from: number
  to: number
  type: StorageItemType
}

type CategoryLeadTime = '2' | '3' | '4' | 'ASK'

export interface Category {
  type: CategoryType
  leadTimeDays: CategoryLeadTime
  ranges: CategoryRange[]
  minimumStorageMonths?: number
}

export enum LoyaltyPartner {
  OBOS = 'OBOS',
  BATE = 'BATE',
}
export enum JobState {
  /* The job is created and awaiting to be run */
  CREATED = 'CREATED',
  /* The job has ran and failed. it will run again until max tries is reached */
  FAILED = 'FAILED',
  /* The job has run til the max amount of trials, now manuall action is required */
  STOPPED = 'STOPPED',
  /* The job has been run and is ok */
  OK = 'OK',
  /* The job has been disrupted manually*/
  DISRUPTED = 'DISRUPTED',
}

export enum PriceType {
  ITEM_SUBSCRIPTION = 'SUBSCRIPTION',
  TAAS = 'TAAS',
}

export enum ProductType {
  STORAGE = 'STORAGE',
  TAAS = 'TAAS',
  SERVICE = 'SERVICE',
  ADD_ON = 'ADD_ON',
  USER_FEATURE = 'USER_FEATURE',
  SHOP = 'SHOP',
}

export enum ProductTarget {
  USER = 'USER',
  ITEM = 'ITEM',
  ORDER = 'ORDER',
}
export enum ProductPriceType {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export enum ProductVisibility {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export enum ServiceLevelType {
  CURBSIDE = 'CURBSIDE',
  CARRYING = 'CARRYING',
  FIRST_DOOR = 'FIRST_DOOR',
}

export enum ServiceOrderState {
  PAYMENT_AUTHORISED = 'PAYMENT_AUTHORISED',
  IN_PROGRESS = 'IN_PROGRESS',
  ORDER_REJECTED = 'ORDER_REJECTED',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
}

export enum Currency {
  NOK = 'NOK',
  SEK = 'SEK',
}

export enum SortOptions {
  SERVICE_FLOW = 'serviceFlow',
  STORAGE_FLOW = 'storageFlow',
  SELL_FLOW = 'sellFlow',
}

export enum UserFeatureKey {
  FLEX_ADDRESS = 'FLEX_ADDRESS',
  PAY_PER_AREA = 'PAY_PER_AREA',
}

export enum LocationType {
  INBOUND_PARTNER = 'INBOUND_PARTNER',
  OUTBOUND_PARTNER = 'OUTBOUND_PARTNER',
  INBOUND_ROUTE = 'INBOUND_ROUTE',
  OUTBOUND_ROUTE = 'OUTBOUND_ROUTE',
  INBOUND_REMOTE = 'INBOUND_REMOTE',
  OUTBOUND_REMOTE = 'OUTBOUND_REMOTE',
  INBOUND_HUB = 'INBOUND_HUB',
  OUTBOUND_HUB = 'OUTBOUND_HUB',
  DEVIATION = 'DEVIATION',
}

export enum Routine {
  O1 = 'O1',
  I1 = 'I1',
  R1 = 'R1',
  P1 = 'P1',
  P7 = 'P7',
}

export enum ProductRevenueCategoryTypes {
  STORAGE = 'STORAGE',
  SHOP = 'SERVICE',
  LOGISTICS = 'LOGISTICS',
  SERVICE = 'SERVICE',
}
