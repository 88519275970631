import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { AccountId } from '@wanda-space/types'
import type { SupportedCountries } from '../consts/enums'
import { useAccount } from '../hooks/useAccount'
import { useProducts } from '../hooks/useProducts'

export function Product({ productId, accountId }: { productId: string; accountId: AccountId }) {
  const { data: user } = useAccount(accountId)
  const { data: products } = useProducts({ country: user?.countryCode as SupportedCountries })

  if (!user && !products) {
    return <>{productId}</>
  }
  const getProductName = products?.products.find((prod) => prod.id === productId)?.localizationKey

  return <FormattedMessage id={`${getProductName}.name`} />
}
